import { tree } from "@/api/goods/goodscategory"
import { goodsSkuPage } from "@/api/goods/goods";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { discountPage } from "@/api/pc"

import { getToken } from "@/utils/auth"
import { addCollect } from "@/api/goods/goods_collect"
import { deleteGoods } from "@/api/member/collection"

import 'swiper/css/swiper.css'
export default {
    name: "dailyUpdate",
    components: {
        Swiper,
        SwiperSlide
    },
    data: () => {
        return {
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            countrySoyego: localStorage.getItem("countrySoyego"),
            id: 0,
            currentPage: 1,
            pageSize: 20,
            col: 4,
            total: 0,
            order: "",
            sort: "",
            sortList: [],
            sortProductList: [],
            loadLeft: true,
            loadRight: true,
            newInOption: {
				slidesPerView: 4,
				spaceBetween: 30,
				slidesPerGroup: 4,
				loop: true,
				pagination: {
					el: '.swiper-pagination'
			  	},
			},
            newInList: [
				{
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}, {
					imgUrl: "http://beeyo-com.oss-accelerate.aliyuncs.com/upload/1/common/images/20220108/20220108013206164161992628636.jpg",
				}
			],
            textAtive: false,
            sortText: 0,
            sizeText: [],
            sizeAtive: false,
            sizeTextList: ['one-size', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
            colorAtive: false,
            colorList: [],
            colorTextList: ['Blue', 'Black', 'Brown', 'Beige', 'Green', 'Gold', 'Grey', 'Multi', 'Pink', 'Purple', 'Red', 'White', 'Yellow', 'Apricot', 'Khaki'],
            slectedId: 0,
            titleRight: 'View All',
            drawer: false,
            direction: 'rtl',
            activeName: 0,
            sortRtlAtive: false,
            lengthText: [],
            lengthTextList: ['Long', 'Maxi', 'Midi', 'Mini', 'Short'],
            sleeveLengthText: [],
            sleeveLengthTextList: ['Cap Sleeve', 'Extra-long Sleeve', 'Half Sleeve', 'Long Sleeve', 'Short Sleeve', 'Sleeveless', 'Three Quarter Length Sleeve'],
            neckLineText: [],
            neckLineTextList: ['Asymmetrical Neck', 'Boat Neck', 'Cold Shoulder', 'Collar', 'Cowl Neck', 'Deep V Neck', 'Halter', 'High Neck', 'V Neck', 'Tie Neck'],
            materialText: [],
            materialTextList: ['Acetate', 'Acrylic', 'Bamboo Fiber', 'Chiffon', 'Corduroy', 'Cotton', 'Cotton Blends', 'Crochet', 'Flannel', 'Giltter', 'Linen', 'Lycan', 'Metallic', 'Velvet'],
            patternTypeText: [],
            patternTypeTextList: ['Argyle-Patterned', 'Block-Coloured', 'Checked', 'Crocodile-Patterned', 'Dogtooth-Patterned', 'Floral', 'Harenquin-Patterned', 'Leoqard-Print', 'Marbled', 'Patterned', 'Pinstriped', 'Soild Colour', 'Spotted', 'Strped'],
            styleText: [],
            styleTextList: ['Basics', 'Boho', 'Casual', 'Cute', 'Dressy', 'Elegant', 'Glamorous', 'Modest', 'Preppy', 'Romantic', 'Sexy', 'Sporty', 'Vintage'],
            sleeveTypeText: [],
            sleeveTypeTextList: ['Balloon Sleeve', 'Batwing Sleeve', 'Butterfly Sleeve', 'Cap Sleeve', 'Dolman Sleeve', 'Flutter Sleeve', 'Puff Sleeve', 'Raglan Sleeve', 'Trumpet Sleeve'],
            priceValue: [0, 1000],
            file_list: {
                color: [],
                size: [],
                length: [],
                sleeveLength: [],
                neckLine: [],
                material: [],
                patternType: [],
                style: [],
                sleeveType: []
            },
            imgTo: "",
            ids: -1,
            bagShop: false,
            titleLeft: '',
            treeList: [],
            treeId1: sessionStorage.getItem("treeOneIdSoyego"),
            filterName: ['1', '2', '3'],
            activeNames2: [],
            is_overlay: false,
            // 需要翻译的静态文本
            translateTextData: {
                Items: '商品',
                SORT_BY: '排序',
                SIZE: '尺寸',
                COLOR: '颜色',
                Category: 'Category',
                Size: '尺寸',
                Colors: '颜色',
                FILTER: '筛选',
                Filter: '筛选',
                Sort_by: '排序',
                Color: '颜色',
                Length: '长度',
                Sleeve_Length: '袖长',
                Neckline: '领口',
                Material: '布料',
                Pattern_Type: '图案类型',
                Style: '风格',
                Most_Popular: '最受欢迎',
                New_Arrivals: '新品推荐',
                Top_Reviewed: 'Top Reviewed',
                Price_Range: 'Price Range',
                Clear_All: 'Clear All',
                Done: 'Done',
                Price_Low_To_High: 'Price Low To High',
                Price_High_To_Low: 'Price High To Low',
                You_may_also_like: 'You may also like',
                View_More: 'View More',
                Recommended: 'Recommended',
                Most_Popular: 'Most Popular',
                New_Arrivals: 'New Arrivals',
                Top_Reviewed: 'Top Reviewed',
                Price_Low_To_High: 'Price Low To High',
                Price_High_To_Low: 'Price High To Low',
                colorTextList: []
            }
        }
    },
    watch: {
        $route: "fetchData"
    },
    created() {
        this.getTranslateText()
    },
    mounted() {
        if(this.$route.query){
            this.id = this.$route.query.categoryId2 || this.$route.query.categoryId3;
            if(this.id == undefined) {
                this.id = sessionStorage.getItem("treeOneIdSoyego");
            }
        }
        this.getTree();
        this.tokenIf();
        this.getGoodsList();
		// this.newInSwiper.slideTo(1, 1000, false)
    },
    computed: {
        swiper () {
			return this.$refs.newInSwiper.swiper
		}
    },
    methods: {
        // 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译颜色
            // this.getTransColor()
            // 翻译
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i]
                        i++;
                    }
                }
            })
        },
        tokenIf() {
            let token = getToken();
            if(token == undefined){
                this.is_overlay = false;
            }else{
                this.is_overlay = true;
            }
        },
        claerSizeColor() {
            this.sizeText = [];
            this.file_list.size = this.sizeText;
            this.colorList = [];
            this.file_list.color = this.colorList;
        },
        handleCurrentChange(val) {
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0

            this.currentPage = val
            this.loadRight = true
            this.getGoodsList()        
        },
        bagShopClick(stuId) {
			this.ids = stuId;
			this.bagShop = true;
		},
        // 触底事件
        loadmore(index) {
            if(this.sortProductList.length > 0 && this.sortProductList.length != this.total){
                this.currentPage = this.currentPage + 1;
                this.loadRight = true
                this.getGoodsList()
            }
        },
        scroll(scrollData) {
            if(scrollData.diff <= 700){
                if(this.sortProductList.length > 0){
                    this.loadmore();
                }
            }
        },
        fetchData() {
            if(this.$route.query){
                this.id = this.$route.query.categoryId2 || this.$route.query.categoryId3;
                if(this.id == undefined) {
                    this.id = sessionStorage.getItem("treeOneIdSoyego");
                }
            }
            this.loadLeft = true
            this.loadRight = true
            this.getTree()
            this.tokenIf()
            this.getGoodsList()
        },
        detailsClick(id) {
            localStorage.setItem('productDetailsDropSoyego', false);
            // localStorage.removeItem("productDetailsSoyego");
            // localStorage.setItem('productDetailsSoyego', id);
            const blank = this.$router.resolve({path: '/productDetails', query: {sku_id: id}})
            window.open(blank.href,'_blank')
            // this.$router.push({ path: '/productDetails', query: { sku_id: id } });
        },
        mouseRight(id) {
            // localStorage.removeItem("productDetailsSoyego");
            localStorage.setItem('productDetailsDropSoyego', false);
            // localStorage.setItem('productDetailsSoyego', id);
            this.imgTo = "/productDetails?sku_id=" + id;
        },
        clearClick() {
            this.sortText = 0;
            this.sizeText = [];
            this.colorList = [];
            this.lengthText = [];
            this.sleeveLengthText = [];
            this.neckLineText = [];
            this.materialText = [];
            this.patternTypeText = [];
            this.styleText = [];
            this.sleeveTypeText = [];
            this.priceValue = [0, 1000];
        },
        sortChange() {
            switch(this.sortText) {
                case 0: this.order = "";this.sort = "";break;
                case 1: this.order = "sale_num";this.sort = "";break;
                case 2: this.order = "create_time";this.sort = "";break;
                case 3: this.order = "evaluate";this.sort = "";break;
                case 4: this.order = "discount_price";this.sort = "asc";break;
                case 5: this.order = "discount_price";this.sort = "desc";break;
                default : break;
            }
        },
        arrowPrev() {
			this.$refs.newInSwiper.$swiper.slidePrev()
			// this.swiper.slidePrev()
		},
		arrowNext() {
			this.$refs.newInSwiper.$swiper.slideNext()
			// this.swiper.slideNext()
		},
        sizeChange(val) {
            if(this.sizeText.includes(val)){ //es6 判断是否包含
				this.sizeText = this.sizeText.filter(function (item){return item !== val;});
			}else{
				this.sizeText.push(val)
			}
            this.file_list.size = this.sizeText;
        },
        colorChange(val) {
            if(this.colorList.includes(val)){ //es6 判断是否包含
				this.colorList = this.colorList.filter(function (item){return item !== val;});
			}else{
				this.colorList.push(val)
			}
            this.file_list.color = this.colorList;
        },
        lengthChange(val) {
            if(this.lengthText.includes(val)){ //es6 判断是否包含
				this.lengthText = this.lengthText.filter(function (item){return item !== val;});
			}else{
				this.lengthText.push(val)
			}
            this.file_list.length = this.lengthText;
        },
        sleeveLengthChange(val) {
            if(this.sleeveLengthText.includes(val)){ //es6 判断是否包含
				this.sleeveLengthText = this.sleeveLengthText.filter(function (item){return item !== val;});
			}else{
				this.sleeveLengthText.push(val)
			}
            this.file_list.sleeveLength = this.sleeveLengthText;
        },
        neckLineChange(val) {
            if(this.neckLineText.includes(val)){ //es6 判断是否包含
				this.neckLineText = this.neckLineText.filter(function (item){return item !== val;});
			}else{
				this.neckLineText.push(val)
			}
            this.file_list.neckLine = this.neckLineText;
        },
        materialChange(val) {
            if(this.materialText.includes(val)){ //es6 判断是否包含
				this.materialText = this.materialText.filter(function (item){return item !== val;});
			}else{
				this.materialText.push(val)
			}
            this.file_list.material = this.materialText;
        },
        patternTypeChange(val) {
            if(this.patternTypeText.includes(val)){ //es6 判断是否包含
				this.patternTypeText = this.patternTypeText.filter(function (item){return item !== val;});
			}else{
				this.patternTypeText.push(val)
			}
            this.file_list.patternType = this.patternTypeText;
        },
        styleChange(val) {
            if(this.styleText.includes(val)){ //es6 判断是否包含
				this.styleText = this.styleText.filter(function (item){return item !== val;});
			}else{
				this.styleText.push(val)
			}
            this.file_list.style = this.styleText;
        },
        sleeveTypeChange(val) {
            if(this.sleeveTypeText.includes(val)){ //es6 判断是否包含
				this.sleeveTypeText = this.sleeveTypeText.filter(function (item){return item !== val;});
			}else{
				this.sleeveTypeText.push(val)
			}
            this.file_list.sleeveType = this.sleeveTypeText;
        },
        handleClose() {
            this.drawer = false;
        },
        handleClose1() {
            this.bagShop = false;
        },
        categoryClick(val) {
            // console.log('djdjdjdjdjdjd')
            // return false;
            this.id = val;
            this.loadRight = true;
            // this.currentPage = 1;
            // this.sortProductList = [];
            this.getLeftTree();
            this.getGoodsList();
        },
        getLeftTree(){
            let tree = sessionStorage.getItem("treeOneIdSoyego");
            this.treeList.forEach(item => {
                if(item.category_id == tree){
                    this.titleLeft = item.category_name;
                    if(this.id == tree){
                        // this.titleLeft = item.category_name;
                        this.titleRight = item.category_name;
                        this.slectedId = item.category_id;
                    }
                    item.child_list.forEach( item1 => {
                        if(this.id == item1.category_id){
                            // this.titleLeft = item1.category_name;
                            this.titleRight = item1.category_name;
                            this.slectedId = item1.category_id;
                        }else{
                            item1.child_list.forEach( item2 => {
                                if(this.id == item2.category_id){
                                    // this.titleLeft = item2.category_name;
                                    this.titleRight = item2.category_name;
                                    this.slectedId = item2.category_id;
                                }
                            })
                        }
                        
                    })
                }
            })
        },
        getTree() {
            tree({
                level: 3
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    if (res.code == 0 && res.data) {
                        let tree = sessionStorage.getItem("treeOneIdSoyego");
                        var id2 = 0;
                        var id3 = 0;
                        if(this.$route.query){
                            id2 = this.$route.query.categoryId2;
                            id3 = this.$route.query.categoryId3
                        }
                        this.treeList = res.data;
                        res.data.forEach(item => {
                            if(item.category_id == tree){
                                this.sortList = item.child_list;
                                this.titleLeft = item.category_name;
                                if(this.id == tree){
                                    // this.titleLeft = item.category_name;
                                    this.titleRight = item.category_name;
                                    this.slectedId = item.category_id;
                                }else if(id2 != 0 && id2 != undefined){
                                    item.child_list.forEach( item1 => {
                                        if(item1.category_id == id2){
                                            // this.titleLeft = item1.category_name;
                                            this.titleRight = item1.category_name;
                                            this.slectedId = item1.category_id;
                                        }
                                    })
                                }else if(id3 != 0 && id3 != undefined){
                                    item.child_list.forEach( item1 => {
                                        item1.child_list.forEach( item2 => {
                                            if(item2.category_id == id3){
                                                // this.titleLeft = item2.category_name;
                                                this.titleRight = item2.category_name;
                                                this.slectedId = item2.category_id;
                                            }
                                        })
                                    })
                                }
                                // 翻译
                                console.log(this.sortList)
                                let strCategory_name = '';
                                this.sortList.forEach(item => {
                                    strCategory_name += item.category_name + '\n'
                                })
                                this.$fanyi.translateData({
                                    query: strCategory_name,
                                    success: resData => {
                                        // 渲染数据
                                        this.sortList.forEach((item,index) => {
                                            this.sortList[index].category_name = resData[index];
                                        })
                                    }
                                })
                            }
                        });
                        this.loadLeft = false;
                    }
                }
            }).catch((err) => {
                this.loadLeft = false;
                this.$message.error(err.message)
            })
        },
        collectClick(item) {
            var that = this;
            var token = getToken();
            if(token == undefined){
                this.$router.push({ path: '/login' })
            }else{
                if(item.is_collect == 0) {
                    addCollect({
                        sku_id: item.sku_id,
                        goods_id: item.goods_id
                    }).then( res => {
                        if(res.code == 0 && res.data){
                            that.sortProductList.forEach(items => {
                                if(items.goods_id == item.goods_id) {
                                    items.is_collect = 1;
                                }
                            });
                            this.$message.success('Collection success');
                        }
                    }).catch( err => {
                        this.$message.error('Collection failed');
                        // console.log(err)
                    })
                }else if(item.is_collect == 1){
                    deleteGoods({
                        goods_id: item.goods_id
                    }).then( res => {
                        if(res.code == 0){
                            that.sortProductList.forEach(items => {
                                if(items.goods_id == item.goods_id) {
                                    items.is_collect = 0;
                                }
                            });
                            this.$message.success('Cancel collection');
                        }
                    }).catch( err => {
                        this.$message.error('Failed to cancel collection');
                        // console.log(err)
                    })
                }
            }
        },
        //获取店铺商品列表
        getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                is_overlay: this.is_overlay,
                category_id: this.id,
                order: this.order,
                sort: this.sort,
                file_list: this.file_list,
                min_price: this.priceValue[0],
                max_price: this.priceValue[1],
                barterRate: this.countrySoyego + "D",
                market_price: true,
                barterRate: this.countrySoyego
            };
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    // if(this.currentPage == 1) {
                        data.list.forEach(item => {
                            item.category_id = item.category_id.split(',');
                            item.goods_image = item.goods_image.split(',');
                            item.goods_spec_format = JSON.parse(item.goods_spec_format);
                            item.discount_price = Number(item.discount_price).toFixed(2)
                            if (item.goods_spec_format != null) {
                                this.$set(item.goods_spec_format[0], 'colorSoyego', item.goods_spec_format[0].value[0].spec_value_id);
                            }
                            this.$set(item, 'imgHover', false);
                        });
                        this.sortProductList = data.list;
                        // 翻译商品列表数据
                        let strText = '';
                        data.list.forEach(item => {
                            strText += item.goods_name + '\n'
                        })
                        this.$fanyi.translateData({
                            query: strText,
                            success: resData => {
                                // 渲染数据
                                data.list.forEach((item,index) => {
                                    this.sortProductList[index].goods_name = resData[index];
                                })
                            }
                        })
                    
                    this.total = res.data.count;
                    this.loadRight = false;
                    // console.log(this.sortProductList)
                }
            }).catch(err => {
                this.loadRight = false;
                this.$message.error(err.message);
            });
        },
        screenClick() {
            this.drawer = false;
            this.loadRight = true;
            this.currentPage = 1;
            this.sortProductList = [];
            this.sortChange();
            this.getGoodsList();
        },
        sortClick(val) {
            this.loadRight = true;
            this.sortText = val;
            this.currentPage = 1;
            this.sortProductList = [];
            this.sortChange();
            this.getGoodsList();
        }
    }
}
